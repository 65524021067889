import React, {FunctionComponent} from 'react';
import FAQListItem from './FAQListItem';
import {useSelector} from 'react-redux';
import {selectCurrentAsset} from '../../../../store/reducers/assets';

export const FAQNames: {[key: string]: string} = {
  connectQuantity: 'connectQuantity',
  deviceTypes: 'deviceTypes',
  howToConnect: 'howToConnect',
  protectedStatus: 'protectedStatus',
  moreDevices: 'moreDevices',
  install: 'install'
};

const FAQList: FunctionComponent = () => {
  const currentAsset = useSelector(selectCurrentAsset);

  const determineDeviceQuantityText = (): string => {
    return `${currentAsset?.device_quantity} ${currentAsset?.device_quantity === 1 ? 'device' : 'devices'}`
  }

  return (
    <div className='faq__list'>
      <FAQListItem name={FAQNames.connectQuantity}>
        <div className='faq__question-wrapper'>
          <div className='faq__question'>
            <p className='faq__answer-text'>How many devices can I connect to my Clario account?
            </p>
          </div>
          <div className='faq__list-icon'/>
        </div>
        <div className='faq__answer'>
          <p className='faq__answer-text'>
           For now, you can connect up to ${determineDeviceQuantityText()}, including your iPhone or Android phone.
          </p>
        </div>
      </FAQListItem>
      <FAQListItem name={FAQNames.deviceTypes}>
        <div className='faq__question-wrapper'>
          <div className='faq__question'>
            <p className='faq__answer-text'>Which types of devices can I connect?
            </p>
          </div>
          <div className='faq__list-icon'/>
        </div>
        <div className='faq__answer'>
          <p className='faq__answer-text'>
            You can connect your iPhone (iOS 11 or later) or your Android phone (Android 7 or later)
            </p>
        </div>
      </FAQListItem>
      <FAQListItem name={FAQNames.howToConnect}>
        <div className='faq__question-wrapper'>
          <p className='faq__question'>How can I connect a device to my account?
          </p>
          <div className='faq__list-icon'/>
        </div>
        <div className='faq__answer'>
          <p className='faq__answer-text'>
            You need to install the Clario app on a new device and log in with your account.
          </p>
        </div>
      </FAQListItem>
      <FAQListItem name={FAQNames.protectedStatus}>
        <div className='faq__question-wrapper'>
          <p className='faq__question'>How do I know that my device is protected?
          </p>
          <div className='faq__list-icon'/>
        </div>
        <div className='faq__answer'>
          <p className='faq__answer-text'>
            You'll see this device in the list of connected devices in the Device section with a Connected tag.
          </p>
        </div>
      </FAQListItem>
      <FAQListItem name={FAQNames.moreDevices}>
        <div className='faq__question-wrapper'>
          <div className='faq__question'>
            <p className='faq__answer-text faq__answer-text--inline'>{`I have connected ${determineDeviceQuantityText()} devices and want to connect more. `}</p>
            <p className='faq__answer-text faq__answer-text--inline'>How can I do this?</p>
          </div>
          <div className='faq__list-icon'/>
        </div>
        <div className='faq__answer'>
          <p className='faq__answer-text'>
            {`Currently, we support connecting up to ${determineDeviceQuantityText()} devices to your account. If you want to add a different device, first disconnect one of ${currentAsset?.device_quantity} that are now connected, and add new
            device in its place.`}
          </p>
        </div>
      </FAQListItem>
      <FAQListItem name={FAQNames.install}>
        <div className='faq__question-wrapper'>
          <p className='faq__question'>
            How can I install Clario on a new device?
          </p>
          <div className='faq__list-icon'/>
        </div>
        <div className='faq__answer'>
          <p className='faq__answer-text'>
            Just follow the download instructions above to install Clario on a new device.
            </p>
          <p className='faq__answer-text faq__answer-text--multi-line'>
            {`If you don't see the download instructions, that means you have exceeded the limit of ${determineDeviceQuantityText()} and you need to disconnect one device to free up a slot.`}
          </p>
        </div>
      </FAQListItem>
    </div>
  );
};

export default FAQList;
