export default class Url {
  static updateQueryStringParams(link: string, params: {[key: string]: string}): string {
    if (!link) {
      return '';
    }

    try {
      let url = new URL(link);
      let searchParams = url.searchParams;

      if (!Object.keys(params).length) {
        return url.toString();
      }

      for (let prop in params) {
        if (Object.prototype.hasOwnProperty.call(params, prop)) {
          searchParams.set(prop, params[prop]);
        }
      }

      return url.toString();
    } catch (error) {
      console.error('Invalid URL:', link);
      return link;
    }
  }

  static getArrayOfParams(link: string) {
    if (!link) {
      return [];
    }

    try {
      const url = new URL(link);
      return Array.from(url.searchParams.entries());
    } catch (error) {
      console.error('Invalid URL:', link);
      return [];
    }
  }

  static getAllQueryParams(link: string) {
    const url = new URL(link);
    return new URLSearchParams(url.search);
  }

  static deleteQueryParamByName(link: string, name: string): void {
    const url = new URL(link);
    const queryParams = new URLSearchParams(url.search);
    return queryParams.delete(name);
  }

  static hasQueryParams(link: string): boolean {
    const url = new URL(link);
    const queryParams = new URLSearchParams(url.search);
    return !!queryParams.toString().length;
  }

  static deleteQueryParams(link: string) {
    const url = new URL(link);

    return url.origin;
  }

  static getQueryParamByName(link: string, name: string): string | null {
    const url = new URL(link);
    const queryParams = new URLSearchParams(url.search);
    return queryParams.get(name);
  }

  static objToQueryString(params: {[key: string]: string}) {
    const keyValuePairs = [];
    for (const key in params) {
      keyValuePairs.push(encodeURIComponent(key) + '=' + encodeURIComponent(params[key]));
    }
    return keyValuePairs.join('&');
  }
}
