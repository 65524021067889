import {useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {determineDownloadLink, getAutoLoginParams} from '../store/actions';
import {useHistory} from 'react-router-dom';
import {selectAutoLoginParamsData} from '../store/reducers/user';
import {MobileDownloadLink} from '../constants';
import getPlatform, {PlatformNames} from '../utils/getPlatform';
import {useTrackingParams} from './useTrackingParams';
import Url from '../utils/url';

export interface DownloadLinkParamsInterface {
    affid: string | null;
    altk?: string;
    cbt?: number;
    webCbt?: number;
    bundleId: string | null,
    ga_cid: string | null,
}

export const useDownloadLinks = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const autoLoginParams = useSelector(selectAutoLoginParamsData);
    const {getAffid, getBundleId, getGaCid} = useTrackingParams();

    const getMobileDownloadLink = () => {
        const platform = getPlatform();
        const downloadLink = MobileDownloadLink;
        
        if (!autoLoginParams) {
            return downloadLink;
        }

        const params = {
            ...autoLoginParams,
            affid: getAffid(),
            bundleId: getBundleId(),
            ga_cid: getGaCid(),
        };

        // Filter out null values and convert to string
        const queryParams = Object.entries(params)
            .filter(([_, value]) => value !== null)
            .reduce((acc, [key, value]) => ({
                ...acc,
                [key]: String(value)
            }), {} as {[key: string]: string});

        return Url.updateQueryStringParams(downloadLink, queryParams);
    };

    useEffect(() => {
        dispatch(getAutoLoginParams(history));
    }, [dispatch, history]);

    return {
        mobileDownloadLink: getMobileDownloadLink(),
    };
};