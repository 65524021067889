import * as React from 'react';
import './DeviceDownloadBlock.scss';
import MobileDownloadBlock from './Mobile/MobileDownloadBlock';
import {useDownloadLinks} from '../../hooks';

interface DeviceDownloadBlockType {
  className?: string,
}

const DeviceDownloadBlock = ({className = ''}: DeviceDownloadBlockType) => {
    const {mobileDownloadLink} = useDownloadLinks();

    return (
        <div className={`device-download__block ${className}`}>
          <MobileDownloadBlock className='device-download__item device-download__item--mobile' downloadLink={mobileDownloadLink} />
        </div>
    );
};

export default DeviceDownloadBlock;
