import React, {FunctionComponent, useEffect} from 'react';
import ContentWrapper from '../../containers/ContentWrapper';
import './ThankYouPage.scss';
import {useSelector} from 'react-redux';
import {AppState} from '../../store/reducers/reducerType';
import withServerErrorHandlingPage from '../../hoc/ServerErrorHandlingPage';
import {Redirect} from 'react-router-dom';
import {Routes} from '../../router/Routes';
import {EmailVerificationEvents, EventContext, ThankYouPageContext, AccountLinks} from '../../constants';
import {Events} from '../../services/events/events';
import Button from '../../components/Buttons/Button';

declare var APP_ENV: 'testing' | 'staging' | 'production';

const ThankYouPage: FunctionComponent = () => {
  const content = useSelector((state: AppState) => state.page.thankYouPageContent);

  if (content == null) {
    // @ts-ignore
    return <Redirect to={Routes.Login}/>
  }

  useEffect(() => {
    sendOpenPageEvent();
  }, []);

  const sendOpenPageEvent = () => {
    switch (content.context) {
      case ThankYouPageContext.emailVerification:
        Events.send({
          context: EventContext.emailVerification,
          event: EmailVerificationEvents.page_opened__email_verification_success,
        });
        break;
      default:
    }
  }

  const handleButtonClick = () => {
    window.open(AccountLinks[APP_ENV], '_blank');
  }

  return (
    <ContentWrapper>
      <div className='thank-you-page'>
        <h2 className='thank-you-page__title'>{content?.title}</h2>
        <p className='thank-you-page__text'>{content?.text}</p>
        <div className='thank-you-page__button-wrapper'>
          <Button
            type='button'
            className=' button--primary button--green profile-options__button'
            action={handleButtonClick}
            title='Back to Clario'
          />
        </div>
      </div>
    </ContentWrapper>
  );
};

export default withServerErrorHandlingPage(ThankYouPage);
