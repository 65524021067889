import React, {useEffect, useState, Dispatch, SetStateAction} from 'react';
import Title from '../../../components/Title';
import './Devices.scss';
import {Download} from './Download';
import FAQ from './FAQ';
import DevicesList from './DevicesList';
import {Events} from '../../../services/events/events';
import {DevicesEvents, EventContext, IconType, WebCbt} from '../../../constants';
import {formDeviceIdArray, determineDeviceQuantityText} from '../../../utils';
import IconsWithBackground from '../../../components/Icons/IconsWithBackground';
import ModalContentWrapper from '../ModalContentWrapper';
import {useSelector} from 'react-redux';
import {selectCurrentAssetDevices, selectDevicesStatus} from '../../../store/reducers/device';
import {selectWebCbt} from '../../../store/reducers/partner';
import {selectCurrentAsset} from '../../../store/reducers/assets';

export const DevicesContext = React.createContext<Partial<DevicesConsumerContext>>({});

export type DevicesConsumerContext = {
  toggleScrollState: Dispatch<SetStateAction<boolean>>;
}

export function useDevicesContext() {
  const context = React.useContext(DevicesContext);

  if (!context) {
    throw new Error(
      'Devices Compound Components must be rendered within the Devices',
    )
  }
  return context;
}

const Devices = () => {
  const [scroll, toggleScrollState] = useState(true);
  const devicesStatus =  useSelector(selectDevicesStatus);
  const currentAssetDevices = useSelector(selectCurrentAssetDevices);
  const webCbt = useSelector(selectWebCbt);
  const currentAsset = useSelector(selectCurrentAsset);

  useEffect(() => {
    sendDeviceEvents();
  }, []);

  const determineDeviceSubtitle = (): string => {
    // If there are for some reason no info about devicesStatus - in order not to show an error
    // the standard number of licenses is displayed
    if (!devicesStatus || devicesStatus.maxQuantity === 0) {
      return '';
    }

    if (webCbt === WebCbt.mkClarioMobile) {
      return `You can log into Clario on up to ${determineDeviceQuantityText(currentAsset)} – iPhone or Android.`
    }

    return `You can log into Clario on up to ${determineDeviceQuantityText(currentAsset)} – iPhone, or Android.`
  }

  const determineTitleText = (): string => {
    if (!devicesStatus?.quantity) {
      return `You haven\'t connected a device yet.`;
    }

    return `You've connected ${devicesStatus?.quantity} ${devicesStatus?.quantity > 1 ? 'devices' : 'device'}.`;
  };

  const sendDeviceEvents = (): void => {
    if (!currentAssetDevices) {
      return;
    }

    if (!currentAssetDevices.length) {
      Events.send({
        context: EventContext.devices,
        event: DevicesEvents.detailed_area__no_devices_popup_expanded,
      });
      return;
    }

    Events.send({
      context: EventContext.devices,
      event: DevicesEvents.detailed_area__devices_popup_expanded,
      data: {deviceId: formDeviceIdArray(currentAssetDevices)}
    })
    ;
  };

  if (devicesStatus == null || currentAssetDevices == null || currentAsset == null) {
    return null;
  }

  return (
    <DevicesContext.Provider value={{
      toggleScrollState: toggleScrollState
    }}>
      <ModalContentWrapper scroll={scroll}>
        <div className='popup__header'>
          <IconsWithBackground iconType={IconType.desktop} className='popup__icon' size='big'/>
          <div className='section__title popup__title'>
            <Title text='Devices' className='popup__title'/>
          </div>
        </div>
        <div className='popup__subtitle'>Here's a list of the devices you're logged into that are protected by Clario. Connect new devices, remove them, or see details about
          each of them.
          <p className='devices__subtitle'>{determineDeviceSubtitle()}</p>
        </div>
        <div className='popup__content'>
          <div className='devices'>
            <div className='devices__header'>
          <span className={`devices__status ${devicesStatus.active ? '' : 'devices__status--inactive'}`}>
            {devicesStatus.name}
          </span>
              <span className={`devices__quantity ${devicesStatus.active ? '' : 'devices__quantity--inactive'}`}>
            {`${devicesStatus?.quantity} of ${devicesStatus.maxQuantity} devices`}
          </span>
            </div>
            <Title text={determineTitleText()} className={`devices__title ${devicesStatus?.active ? '' : 'devices__title--inactive'}`}/>
            {devicesStatus?.quantity || currentAssetDevices.length
              ? <DevicesList devicesList={currentAssetDevices} devicesStatus={devicesStatus}/>
              : <p className='devices__description'>
                Connect your first device to Clario. Just install the Clario app on it and log in to your account.
              </p>
            }
            {devicesStatus?.quantity < currentAsset.device_quantity && devicesStatus?.quantity > 0
              ? (
                <div className='devices__connect-more'>
                  <h3 className='devices__block-title devices__connect-more-title'>Connect another device? </h3>
                  <p className='devices__text devices__connect-more-text'>
                    We're happy to secure your digital life on any smartphone you have. Just follow the steps below and log in.
                  </p>
                </div>
              )
              : null}
            {devicesStatus?.quantity < currentAsset.device_quantity ? <Download/> : null}
            <FAQ/>
          </div>
        </div>
      </ModalContentWrapper>
    </DevicesContext.Provider>
  );
};

export default Devices;
