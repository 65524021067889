import React from 'react';
import './Download.scss';
import {MobileDownloadBlockItem} from './DownloadBlock';
import {useDownloadLinks} from '../../../../hooks';

const Download = () => {
  const {mobileDownloadLink} = useDownloadLinks();

  return (
      <div className='devices__download download'>
        <MobileDownloadBlockItem downloadLink={mobileDownloadLink} />
      </div>
  );
};

export default Download;
