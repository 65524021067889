import {History} from 'history';
import {Dispatch} from 'redux';
import {deviceService} from '../../../services/device/device';
import {AppEnv, DeviceConstants, TestingAffid, DeviceItemScreenType} from '../../../constants';
import {
  GetDevicesListRequestReduxAction,
  GetDevicesListSuccessReduxAction,
  GetDevicesListFailureReduxAction,
  SetDevicesStatusReduxAction,
  DisconnectDeviceRequestReduxAction,
  DisconnectDeviceSuccessReduxAction,
  DisconnectDeviceFailureReduxAction,
  SetDeviceItemScreenTypeReduxAction,
  SetCurrentAssetDevicesReduxAction
} from './deviceActionsTypes';
import {DevicesListResponseFromApi, Device, DevicesStatus} from '../../../services/device/deviceTypes';
import {Asset, License} from '../../../services/assets/assetsTypes';
import Url from '../../../utils/url';
import {getAssetsList} from '..';
import {isEmpty, objectOmitNull} from '../../../utils';
import {determineAffid} from '../../../utils/getAffid';

declare var APP_ENV: string;

export function getDevicesList(history: History) {
  return (dispatch: Dispatch<GetDevicesListRequestReduxAction | GetDevicesListSuccessReduxAction | GetDevicesListFailureReduxAction>) => {
    dispatch(getDevicesListRequest());

    return deviceService.getDevicesList(history)
      .then(
        (devicesList) => {
          dispatch(getDevicesListSuccess(devicesList));
        },
        error => {
          dispatch(getDevicesListFailure(error));
        }
      );
  };

  function getDevicesListSuccess(devicesList: DevicesListResponseFromApi): GetDevicesListSuccessReduxAction {
    return {type: DeviceConstants.GET_DEVICES_LIST_SUCCESS, payload: {devicesList}}
  }

  function getDevicesListFailure(error: Error): GetDevicesListFailureReduxAction {
    return {type: DeviceConstants.GET_DEVICES_LIST_FAILURE, payload: error}
  }
}

export function getDevicesListRequest(): GetDevicesListRequestReduxAction {
  return {type: DeviceConstants.GET_DEVICES_LIST_REQUEST, payload: {}}
}

export function disconnectDevice(licenseID: string, deviceId: string, history: History) {
  return (dispatch: Dispatch<DisconnectDeviceRequestReduxAction | DisconnectDeviceSuccessReduxAction | DisconnectDeviceFailureReduxAction | any>) => {
    disconnectDeviceRequest(deviceId);
    return deviceService.disconnect(licenseID, history)
      .then(
        () => {
          dispatch(disconnectDeviceSuccess(deviceId));
          dispatch(getAssetsList(history));
          dispatch(getDevicesList(history));
        },
        error => {
          dispatch(disconnectDeviceFailure(error, deviceId));
        }
      );
  };

  function disconnectDeviceSuccess(deviceId: string): DisconnectDeviceSuccessReduxAction {
    return {type: DeviceConstants.DISCONNECT_DEVICE_SUCCESS, payload: {deviceId: deviceId}}
  }

  function disconnectDeviceFailure(error: Error, deviceId: string): DisconnectDeviceFailureReduxAction {
    return {type: DeviceConstants.DISCONNECT_DEVICE_FAILURE, payload: {error: error, deviceId: deviceId}}
  }
}

export function disconnectDeviceRequest(deviceId: string) {
  return (dispatch: Dispatch<DisconnectDeviceRequestReduxAction>) => {
    dispatch({type: DeviceConstants.DISCONNECT_DEVICE_REQUEST, payload: {deviceId: deviceId}});
  };
}

export function setDeviceItemScreenType(deviceId: string, screenType: DeviceItemScreenType) {
  return (dispatch: Dispatch<SetDeviceItemScreenTypeReduxAction>) => {
    dispatch({type: DeviceConstants.SET_DEVICE_ITEM_SCREEN_TYPE, payload: {deviceId, screenType}});
  }
}

export function setCurrentAssetDevices(devicesList: Array<Device> | []): SetCurrentAssetDevicesReduxAction {
  return {type: DeviceConstants.SET_CURRENT_ASSET_DEVICES, payload: {devicesList}}
}

export function getLicenseIdByDevice(deviceId: string, currentAsset: Asset | null): string {
  if (currentAsset == null || !Object.keys(currentAsset).length) {
    return '';
  }

  if (currentAsset.licenses == null || !currentAsset.licenses.length) {
    return '';
  }

  const currentLicense = currentAsset.licenses.find((license) => license.device_id === deviceId);

  // Handle not found license for specified deviceId
  if (currentLicense == null) {
    return '';
  }

  return currentLicense.license_id;
}

export function determineDevicesStatus(currentAsset: Asset | null) {
  return (dispatch: any) => {
    const deviceQuantity = determineDeviceQuantity(currentAsset);

    // No attached devices
    if (!deviceQuantity) {
      dispatch(setDevicesStatus({name: 'Not connected', active: false, quantity: deviceQuantity, maxQuantity: currentAsset?.device_quantity || 0}));
      return;
    }

    dispatch(setDevicesStatus({name: 'Connected', active: true, quantity: deviceQuantity, maxQuantity: currentAsset?.device_quantity || 0}));
  }
}

export function reduceConnectedDevicesQuantity(devicesStatus: DevicesStatus) {
  return (dispatch: any) => {
    let updatedDevicesStatus = {...devicesStatus, quantity: devicesStatus.quantity - 1};

    if (!updatedDevicesStatus.quantity) {
      dispatch(setDevicesStatus({name: 'Not connected', active: false, quantity: 0, maxQuantity: devicesStatus.maxQuantity}));
      return;
    }

    dispatch(setDevicesStatus(updatedDevicesStatus));
  }
}

export function setDevicesStatus(devicesStatus: DevicesStatus) {
  return (dispatch: Dispatch<SetDevicesStatusReduxAction>) => {
    dispatch({type: DeviceConstants.SET_DEVICES_STATUS, payload: {status: devicesStatus}});
  }
}

export function determineDeviceQuantity(currentAsset: Asset | null): number {
  let deviceQuantity = 0;

  if (currentAsset != null && !isEmpty(currentAsset)) {
    deviceQuantity = currentAsset.licenses.filter((license: License) => license.device_id.length).length;
  }

  return deviceQuantity;
}

export function determineDownloadLink<T>(link: string, params?: T): string {
  if (!link || typeof link !== 'string') {
    return '';
  }

  const downloadLink = link.trim();
  if (!downloadLink) {
    return '';
  }

  const affid = APP_ENV === AppEnv.production ? determineAffid() : TestingAffid;
  
  const downloadLinkParams = objectOmitNull({
    ...params,
    affid
  });

  return Url.updateQueryStringParams(downloadLink, downloadLinkParams);
}
